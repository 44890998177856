@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 p-2 mt-1;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply rounded border-gray-300 text-indigo-600 focus:ring-indigo-500;
  }
  label {
    @apply block text-sm font-medium text-gray-700 text-left;
  }
}
